<template>
    <!-- Modal -->
    <div
        class="modal fade"
        id="accessibilityModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="accessibilityModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="heading modal-title" id="accessibilityModalLabel">
                        {{ $t('accessibility.title') }}
                    </h3>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <h3 class="heading text-primary">{{ $t('accessibility.textSize') }}</h3>
                    <p>
                        {{ $t('accessibility.content') }}
                    </p>
                    <div class="d-flex">
                        <div class="mr-5">
                            <h3 class="heading text-primary">{{ $t('accessibility.rowSpacing') }}</h3>
                            <div class="custom-control custom-radio">
                                <input
                                    v-model="lineHeight"
                                    :value="true"
                                    type="radio"
                                    id="height_1_5"
                                    name="lineHeightRadio"
                                    class="custom-control-input"
                                />
                                <label class="custom-control-label" for="height_1_5">1.5</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input
                                    v-model="lineHeight"
                                    :value="false"
                                    type="radio"
                                    id="height_default"
                                    name="lineHeightRadio"
                                    class="custom-control-input"
                                />
                                <label class="custom-control-label" for="height_default"
                                >{{ $t('accessibility.default') }}</label
                                >
                            </div>
                        </div>
                        <div>
                            <h3 class="heading text-primary">{{ $t('accessibility.contrast') }}</h3>
                            <div class="custom-control custom-radio">
                                <input
                                    v-model="highContrast"
                                    value="H"
                                    type="radio"
                                    id="contrast_high"
                                    name="contractRadio"
                                    class="custom-control-input"
                                />
                                <label class="custom-control-label" for="contrast_high"
                                >{{ $t('accessibility.reversed') }}</label
                                >
                            </div>
                            <div class="custom-control custom-radio">
                                <input
                                    v-model="highContrast"
                                    value="L"
                                    type="radio"
                                    id="contrast_default"
                                    name="contractRadio"
                                    class="custom-control-input"
                                />
                                <label class="custom-control-label" for="contrast_default"
                                >{{ $t('accessibility.default') }}</label
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button
                        @click="resetAccessibilityRules"
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                    >
                        {{ $t('accessibility.reset') }}
                    </button>
                    <button
                        @click="confirmAccessibilityRules"
                        type="button"
                        class="btn btn-primary"
                        data-dismiss="modal"
                    >
                        {{ $t('accessibility.confirm') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as $ from "jquery";
import EventBus from "../eventBus";

export default {
    data() {
        return {
            highContrast: 'L',
            lineHeight: false
        };
    },
    mounted() {
        if (localStorage.highContrast) {
            this.highContrast = localStorage.highContrast;
        } else {
            this.highContrast = 'L';
        }
        if (localStorage.lineHeight === 'H') {
            this.lineHeight = localStorage.lineHeight;
        }
    },

    watch: {
        highContrast: function (val) {
            if (val === 'H') {
                this.allowHighContrastCSS();
            } else {
                this.disableHighContrastCSS();
            }
            EventBus.emit("CONTRAST", {contrast: this.highContrast});
        },

        lineHeight: function (val) {

            if (val) {
                this.setLineHeight15();
            } else {
                this.setLineHeightNormal();
            }
        }
    },

    methods: {
        confirmAccessibilityRules() {
            localStorage.highContrast = this.highContrast;
            localStorage.lineHeight = this.lineHeight ? 'H' : 'L';

            if (this.highContrast === 'H') {
                this.allowHighContrastCSS();
            } else {
                this.disableHighContrastCSS();
            }

            if (this.lineHeight) {
                this.setLineHeight15();
            } else {
                this.setLineHeightNormal();
            }
        },

        resetAccessibilityRules() {
            if ((!this.highContrast || this.highContrast === 'L') && !this.lineHeight) {
                return;
            }
            this.allowHighContrastCSS();
            this.setLineHeightNormal();
            this.highContrast = 'L';
            this.lineHeight = false;
            localStorage.highContrast = 'L';
            localStorage.lineHeight = 'L';
        },

        disableHighContrastCSS() {
            $("#highContrast").attr("disabled", "disabled");
        },

        allowHighContrastCSS() {
            $("#highContrast").removeAttr("disabled");
        },

        setLineHeight15() {
            $("head #lineHeight").removeAttr("disabled");
        },

        setLineHeightNormal() {
            $("head #lineHeight").attr("disabled", "disabled");
        }

    }
};
</script>
