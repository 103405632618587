import axios from "axios";

axios.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        config.withCredentials = true;
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

class PortsDataService {
    get apiUrl() {
        return (window as any).appSettings.ApiBaseUrl;
    }

    get gisUrl() {
        return (window as any).appSettings.GisBaseUrl;
    }
    
    async queryPorts() {
        return await axios.get(`${this.apiUrl}/ports/public-active`);
    }

    async queryPort(publicId: string) {
        return await axios.get(`${this.apiUrl}/ports/${publicId}/public-details`);
    }

    async queryReservoirs() {
        return await axios.get(`${this.apiUrl}/ports/reservoirs`);
    }

    async queryAdditionalServiceTypes() {
        return await axios.get(`${this.apiUrl}/ports/additional-service-types`);
    }

    async queryFileDownloadLink(portId: string, fileId: string) {
        return await axios.get(`${this.apiUrl}/ports/${portId}/public-files/${fileId}`);
    }
    async portRegulationAsPdf(portId: string, inEstonian: boolean){
        return await axios.get(`${this.apiUrl}/public-regulation/pdf/`, {
            params: {
                id: portId,
                inEstonian: inEstonian
            }, responseType: 'arraybuffer'
        });
    }

    async news() {
        return await axios.get(`${this.apiUrl}/news/active`, {
            params: {}
        });
    }

    async newsById(id: string) {
        return await axios.get(`${this.apiUrl}/news/${id}`, {
            params: {}
        });
    }

    async sendEmail(name: string, email: string, subject: string, content: string, captchaToken: string) {
        return await axios.post(`${this.apiUrl}/contact/public-contact`, {
            name,
            email,
            subject,
            content,
            captcha: captchaToken
        });
    }

    async changeToEstonian() {
        await axios.post(`${this.apiUrl}/cultures/change-to-estonian`, {});
    }

    async changeToEnglish() {
        await axios.post(`${this.apiUrl}/cultures/change-to-english`, {});
    }

    async getLanguage() {
        return await axios.get(`${this.apiUrl}/cultures/current-culture`, {});
    }
}

const portsDataService = new PortsDataService();
export default portsDataService;
