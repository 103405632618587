<template>

    <transition name="fade" mode="out-in">

        <div v-if="message != ''" id="info-box" :class="error ? 'failed' : 'success'">
            <p id="info-message" v-text="message"></p>
            <p class="close" @click="closeMessage()">X</p>

        </div>
    </transition>
</template>

<script>
import EventBus from "../eventBus";

export default {
    name: "PortMessage",
    data() {
        return {
            message: "",
            error: false
        };
    },
    mounted() {
        EventBus.on("FLASHMESSAGE", payload => {
            this.message = payload.message;
            this.error = payload.error;
            setTimeout(() => this.message = "", 3000)
        });
    },
    methods: {
        closeMessage() {
            this.message = "";
        }
    }
};
</script>

<style scoped>
</style>
