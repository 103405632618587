import App from "./App.vue";
import "bootstrap";
import "./scss/app.scss";
import router from "./router";

import i18n from "./i18n";
import axios from "axios";
import PortsDataService from "./service/PortsDataService";
import {createApp} from 'vue';

const developmentSettings = {
    ApiBaseUrl: "https://localhost:7119",
    GisBaseUrl: "https://xgis.maaamet.ee/xgis2/page/app/sadamakaart",
    MainAppUrl: "http://localhost:5173",
    PasswordAuthEnabled: "true",
    CaptchaSiteKey: "6LcxQ_kkAAAAAKqSORehtjCxJwMIESZ5Am54ftzI" 
};
const getData = async (url: string) => {
    return (await axios.get(url)).data;
}

const getSettings = async () => process.env.NODE_ENV === 'development'
    ? developmentSettings
    : await getData(`/settings`);

getSettings()
    .then(settings => (window as any).appSettings = settings)
    .then(async () => {
        const apiLanguage = ((await PortsDataService.getLanguage()).data as string).split('-')[0];

        // Some other sites have links with language parameter
        const languageParameter = new URLSearchParams(window.location.search).get('language');
        const languageOverride: 'et' | 'en' | null = languageParameter === 'et' || languageParameter === 'en' ? languageParameter : null;

        // Change language if it overrides the API language
        if (languageOverride && languageOverride !== apiLanguage) {
            switch (languageOverride) {
                case 'et':
                    await PortsDataService.changeToEstonian();
                    break;
                case 'en':
                    await PortsDataService.changeToEnglish();
                    break;
                default:
                    console.error("Unknown language parameter value: " + languageOverride);
            }
        }
        i18n.global.locale = (languageOverride ?? apiLanguage) as "et" | "en";
        document.title = i18n.global.t('landingPage.header') as string;
    })
    .then(async () => {
        const app = createApp({
            extends: App
        });

        app.use(router);
        app.use(i18n);
        app.mount("#app");
    });


